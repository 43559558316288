<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between flex-wrap my-1">
        <h4>{{ $t("settings.styles.title") }}</h4>
        <div class="d-flex">
          <b-button
            v-b-modal.modal-reset-style
            variant="outline-primary"
            class="ml-1"
          >
            {{ $t("settings.styles.reset-styles") }}
          </b-button>
          <b-button variant="primary" class="ml-1" @click="saveStyle()">
            {{ $t("form.actions.save") }}
          </b-button>
        </div>
      </div>
      <b-row class="d-flex flex-wrap">
        <b-col cols="6">
          <b-form-group
            :label="$t('settings.styles.primary-color')"
            label-for="primary color"
          >
            <b-form-input
              id="primary color"
              v-model="primaryColor"
              type="color"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            :label="$t('settings.styles.secondary-color')"
            label-for="secondary color"
          >
            <b-form-input
              id="secondary color"
              v-model="secondaryColor"
              type="color"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <p class="mt-1 mb-25 small d-flex align-items-center">
            <feather-icon class="mr-50 text-danger" icon="AlertTriangleIcon" />
            <span class="font-italic">{{ $t("backoffice.settings.note-dark-color") }}</span>
          </p>
          <p class="mb-0 small d-flex align-items-center">
            <feather-icon class="mr-50 text-danger" icon="AlertTriangleIcon" />
            <span class="font-italic">
              {{ $t("backoffice.settings.note-light-color") }}
            </span>
          </p>
        </b-col>
      </b-row>
    </b-card>
    <!-- Unshare modal -->
    <b-modal
      id="modal-reset-style"
      :title="$t('settings.styles.reset-styles')"
      size="lg"
      hide-footer
      @change="$emit('change', false)"
    >
      <feather-icon
        size="30"
        icon="AlertTriangleIcon"
        class="d-block mx-auto text-danger mb-1"
      />
      <h5 class="mb-2 px-2 text-center">
        {{ $t("settings.styles.message") }}
      </h5>
      <div class="d-flex justify-content-center mb-2 flex-wrap">
        <b-button
          variant="primary"
          target="_blank"
          class="mt-25"
          @click="closeModal"
        >
          {{ $t("settings.styles.options.no") }}
        </b-button>
        <b-button
          variant="outline-danger"
          target="_blank"
          class="ml-md-1 mt-25"
          @click="resetStyle"
        >
          {{ $t("settings.styles.options.yes") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ColorTranslator } from "colortranslator";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";

export default {
  name: "Styles",
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      primaryColor: "#28ABB9",
      secondaryColor: "#82868b",
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
  },
  created() {
    if (this.currentCollective.themeCostumization?.primaryColor) {
      const { primaryColor } = this.currentCollective.themeCostumization;
      this.primaryColor = ColorTranslator.toHEX(primaryColor);
    }
    if (this.currentCollective.themeCostumization?.secondaryColor) {
      const { secondaryColor } = this.currentCollective.themeCostumization;
      this.secondaryColor = ColorTranslator.toHEX(secondaryColor);
    }
  },
  methods: {
    async saveStyle() {
      try {
        const response = await this.$store.dispatch("editItem", {
          noSet: true,
          item: {
            itemType: "communityCustomizations",
            requestConfig: {
              theme: {
                primaryColor: ColorTranslator.toHSL(this.primaryColor),
                secondaryColor: ColorTranslator.toHSL(this.secondaryColor),
              },
            },
          },
        });
        const colors = response.data.community_customizations.theme;
        document.documentElement.style.setProperty(
          "--primary-color",
          colors.primaryColor
        );
        let hsl = colors.primaryColor;
        hsl = hsl.substring(4, hsl.length - 1);
        hsl = hsl.split(",");
        document.documentElement.style.setProperty("--primary-color-h", hsl[0]);
        document.documentElement.style.setProperty("--primary-color-s", hsl[1]);
        document.documentElement.style.setProperty("--primary-color-l", hsl[2]);

        document.documentElement.style.setProperty(
          "--secondary-color",
          colors.secondaryColor
        );
        let hslSecondary = colors.secondaryColor;
        hslSecondary = hslSecondary.substring(4, hslSecondary.length - 1);
        hslSecondary = hslSecondary.split(",");
        document.documentElement.style.setProperty(
          "--secondary-color-h",
          hslSecondary[0]
        );
        document.documentElement.style.setProperty(
          "--secondary-color-s",
          hslSecondary[1]
        );
        document.documentElement.style.setProperty(
          "--secondary-color-l",
          hslSecondary[2]
        );
        this.notifySuccess(this.$t("backoffice.settings.messages.success"));
      } catch {
        this.notifyError(this.$t("backoffice.settings.messages.error"));
      }
    },
    async resetStyle() {
      (this.primaryColor = "#28ABB9"), (this.secondaryColor = "#82868b");
      await this.saveStyle();
    },
    closeModal() {
      this.$bvModal.hide("modal-reset-style");
    },
  },
};
</script>

<style></style>
